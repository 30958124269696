export const authors = {
  "Abdulshaqur Suleiman": {
    avatar: "https://github.com/SuleimanAbdulshaqur.png?size=512",
  },
  "Alex Prokop": {
    avatar: "https://github.com/baseten.png?size=512",
  },
  "Alec Rosenbaum": {
    avatar: "https://github.com/AlecRosenbaum.png?size=512",
  },
  "André Junges": {
    avatar: "https://github.com/andrejunges.png?size=512",
  },
  "Andrés Barreiro": {
    avatar: "https://github.com/drewler.png?size=512",
  },
  "Anthony Nemitz": {
    avatar: "/avatars/anthony.jpg",
  },
  "Bart Gryszko": {
    avatar: "https://github.com/bartgryszko.png?size=512",
  },
  "Claudia Oprea": {
    avatar: "https://github.com/anamariacla.png?size=512",
  },
  "Doug Greenbaum": {
    avatar: "https://github.com/dggreenbaum.png?size=512",
  },
  "Drew Geraets": {
    avatar: "https://github.com/drewgeraets.png?size=512",
  },
  "Jakub Hajek": {
    avatar: "https://github.com/jakubhajek.png?size=512",
  },
  "João Sampaio": {
    avatar: "https://github.com/jpmelos.png?size=512",
  },
  "Joe Kemp": {
    avatar: "https://github.com/jkemp101.png?size=512",
  },
  "Kaylee Novak": {
    avatar: "https://github.com/kayleenovak.png?size=512",
  },
  "Krishan Madan": {
    avatar: "https://github.com/krishanmarco.png?size=512",
  },
  "Lukáš Mladý": {
    avatar: "https://github.com/lukasmlady.png?size=512",
  },
  "Marco Tisi": {
    avatar: "https://github.com/marcotisi.png?size=512",
  },
  "Phil Freo": {
    avatar: "https://github.com/philfreo.png?size=512",
  },
  "Scott Mahr": {
    avatar: "https://github.com/essmahr.png?size=512",
  },
  "Stefan Wójcik": {
    avatar: "https://github.com/wojcikstefan.png?size=512",
  },
  "Thomas Steinacher": {
    avatar: "https://github.com/thomasst.png?size=512",
  },
  "Trey Cucco": {
    avatar: "https://github.com/treycucco.png?size=512",
  },
  "Vicenç Juan Tomàs Monserrat": {
    avatar: "https://github.com/vtomasr5.png?size=512",
  },
  "Vyacheslav Tverskoy": {
    avatar: "https://github.com/tsx.png?size=512",
  },
  "Vitor Buzinaro": {
    avatar: "https://github.com/buzinas.png?size=512",
  }
};
